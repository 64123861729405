.niBx {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.niHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}
.box_allergens {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.allergens {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.allergens > div {
  width: calc(25% - 18px);
}
.allergens_item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.allergens_value {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}
.grid_1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.grid_1 > div {
  flex: 1;
}
.grid_2 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.col_nutrional {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.nutrionals {
  display: flex;
  flex-direction: column;
  gap: 11px;
  max-height: 354px;
  overflow-y: auto;
}
.nutrional_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}
.nutrional_item > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}
.nutritional_icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  background: #e1e9f1;
}
.col_health {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 767px) {
  .grid_1 {
    flex-direction: column;
  }
}