.article {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}
.layout1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.price {
  font-size: 16px;
  font-weight: 700;
}
.article h1 {
  color: inherit;
}
