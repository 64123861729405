.item1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: var(--borderColor) 1px solid;
  padding: 16px;
  background-color: var(--white);
}
.item1 > picture {
  width: 100px;
}
.item1 > picture img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.item1_content {
  width: calc(100% - 130px);
  padding-left: 30px;
  border-left: var(--borderColor) 1px solid;
}
.flex_row1 {
  display: flex;
  justify-content: space-between;
}
.article button {
  padding-right: 24px;
  padding-left: 24px;
}
