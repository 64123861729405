.box {
  position: relative;
}
.box input {
  padding-right: 48px;
}
.searchButton {
  position: absolute;
  top: 50%;
  right: var(--inputHPadding);
  background: transparent;
  border: 0;
  outline: none;
  line-height: 0;
  cursor: pointer;
  transform: translateY(-50%);
}
