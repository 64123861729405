.article {
    position: relative;
    padding: 29px 0;
    background: var(--primary);
    overflow: hidden;
    z-index: 1;
}
.article::before {
    content: '';
    position: absolute;
    top: -60%;
    left: -60%;
    width: 100vw;
    height: 100vw;
    border-radius: 50%;
    opacity: 0.55;
    background: var(--primary);
    z-index: -1;
}
.bx {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bx > div {
    width: calc(50% - 120px);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 40px;
}
.bx > div p {
    font-size: 20px;
}
.bx > div button {
    width: 180px;
}
.bx > picture {
    width: calc(50% + 80px);
}
.bx > picture img {
    width: 100%;
    height: auto;
}

@media (max-width: 991px) {
    .bx {
        flex-direction: column;
        gap: 30px;
    }
    .bx > div,
    .bx > picture {
        width: 100%;
    }
    .bx > div {
        padding-left: 0px;
        gap: 16px;
        align-items: center;
    }
    .bx > div p {
        font-size: 17px;
    }
}