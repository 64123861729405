.article {
  background-color: var(--borderColor);
  padding-top: var(--articleVPadding);
  padding-bottom: calc(var(--articleVPadding) + 100px);
  height: 100%;
}
.box {
  width: 100%;
  max-width: var(--boxWidth);
  margin-right: auto;
  margin-left: auto;
  padding: var(--boxHPadding);
}
.featuredContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
}
.featuredContent > h3 {
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: var(--gray) 1.5px solid;
}
.featuredContent > h6 {
  max-width: 672px;
  line-height: 1.5;
  word-break: break-all;
}

.featuredItem picture {
  background-color: var(--borderColor);
}
.featuredItem img {
  width: 100%;
  height: auto;
}
.badge {
  font-size: 18px;
  width: 110%;
  border-radius: 8px;
  background-color: var(--borderColor);
  justify-content: flex-start;
  box-shadow: 1px 2px 9px #9a9c9f;
  padding-left: 10px;
  justify-content: space-between;
  padding-right: 15px;
}
.badge span {
  text-align: left;
}
.dashed {
  width: 170px;
  line-height: 0;
  overflow: hidden;
}
.dashed svg line {
  stroke: var(--gray);
}
.featuredItem p {
  word-break: break-all;
  line-height: 1.5;
}
.title {
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.title_txt {
  color: #000;
  text-align: left;
  align-self: flex-start;
  display: inline;
}
.order_div {
  display: grid;
  width: 50%;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
}
.order_div p {
  margin-left: 8%;
  color: #2a2f31;
  font-weight: 500;
  font-size: 16px;
}
.btn {
  background-color: #de8383;
  border: 2px solid #de8383;
  padding: 10px;
  width: 180px;
  font-weight: bold;
}
.btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.order_txt {
  margin-left: 15%;
}
.line {
  background-color: #de8383;
  height: 8px;
  width: 25px;
}
.menubox {
  margin-bottom: 20px;
}
.branches {
  display: flex;
  flex-direction: row;
}
.categories_names {
  border: ipx solid black;
}
.brand_banner {
  position: sticky;
  top: -132px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
}
.brand_banner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(20deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
  z-index: -1;
}
.bb_flex1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  color: var(--white);
}
.wrap_1 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.box_menu1 {
  width: calc(100% - 390px);
}
.box_cart1 {
  width: 360px;
  background-color: var(--white);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
}
.title {
  background: #ffffff;
  padding: 5px 16px;
  border: 0.5px solid var(--primary);
}
.type_item {
  margin-top: 41px;
}
.xwrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 24px;
}
.xy_wrap button + button {
  margin: 0;
}
.xy_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.button_item {
  position: relative;
  width: calc(33.33% - 16px);
  max-width: 273px;
  text-align: left;
  height: auto;
  flex-direction: column;
  border-radius: 6px;
  background-color: var(--white);
  padding: 0;
}
.body1 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}
.body1 picture {
  width: 111px;
  height: 111px;
  padding: 3px;
  border: var(--borderColor) 0.5px solid;
  border-radius: 6px;
}
.button_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.body1 > div {
  width: calc(100% - 119px);
}
.footer1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 8px;
  border-radius: 0px 0px 8px 8px;
}
.button5 {
  width: 90px;
}

.c_h5 {
  color: var(--primary);
}
.c_h6_2 {
  margin-top: 8px;
}
.brand_xwrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 4px;
}
.brand_xwrap button + button {
  margin: 0;
}
.brand_tab {
  width: 164px;
  height: 121px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  outline: 0;
  box-shadow: none;
  background: #ffffff;
  color: #2a2f31;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  border: 0.5px solid #9a9c9f;
  border-radius: 0;
}
.brand_tab.active {
  background: #de8383;
  color: #ffffff;
  border-color: #de8383;
  /* var(--primary); */
}
.brand_tab img {
  height: 57.48px;
  width: auto;
  max-width: 100%;
}
.modal_header {
  display: flex;
  justify-content: space-between;
}
.check_name {
  display: flex;
  align-items: center;
}
.check_name input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.check_name h6 {
  font-size: 14px;
}
.modal_footer {
  padding: 20px 0;
  margin-top: 30px;
  border-top: var(--borderColor) 2px solid;
}
.modal_footer button {
  padding-right: 16px;
  padding-left: 16px;
}

.brand_sticky {
  position: sticky;
  top: 68px;
  background-color: var(--accentbg);
  padding-top: 20px;
  z-index: 12;
}
.scroll_links {
  display: flex;
  align-items: center;
  gap: 60px;
  padding-top: 40px;
  /* z-index: 12; */
}
.scroll_link_item {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 4px 0;
  text-transform: uppercase;
  color: #6e7072;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.scroll_link_item.active {
  color: #2a2f31;
  border-bottom: 3px solid #de8383;
}
.twoCharacter {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--white);
  text-transform: uppercase;
  border-radius: 50%;
}
.active .twoCharacter {
  background-color: var(--white);
  color: var(--primary);
}

.grid_menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.col_menu {
  width: calc(50% - 15px);
  flex-grow: 1;
}
.menu_item {
  padding: 20px;
  border: 1px solid #de8383;
  display: flex;
  align-items: center;
  gap: 16px;
}
.menu_item picture {
  width: 100px;
}
.menu_item picture img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.menu_item_content {
  width: calc(100% - 116px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
