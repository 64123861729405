@import url('react-phone-number-input/style.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --black: #000000;
  --black-rgb: 0, 0, 0;
  --dark: #121212;
  --gray: #9a9c9f;
  --gray1: #424242;
  --danger: #ff0022;
  --textColor: #2a2f31;
  --foreGround: #2a2f31;
  --foreGroundGray: #595e60;
  --borderColor: #dadcdf;
  --accentbg: #eeeeee;
  --bgOverlay: rgba(0, 0, 0, 0.66);
  --bgOverlay1: rgba(42, 47, 49, 0.9);

  --buttonHeight: 44px;

  --gap8: 8px;
  --gap10: 10px;
  --gap20: 20px;
  --gap30: 30px;
  --gap40: 40px;

  --lableColor: #2a2f31;
  --inputFontSize: 16px;
  --inputHeight: 44px;
  --inputLineHeight: 44px;
  --inputHPadding: 14px;
  --inputBackground: #ffffff;
  --inputColor: #2a2f31;
  --inputPlaceholderColor: #9a9c9f;
  --inputBorder: #cbd2e0 2px solid;
  --inputRadius: 6px;

  --buttonFontSize: 13px;
  --buttonFontWeight: 600;
  --buttonLineHeight: 130%;
  --buttonHeight: 36px;
  --accentForeGround: #ffffff;

  --boxWidth: 100%;
  --boxHPadding: 14px;
  --articleVPadding: 40px;
  --contBxHPadding: 16px;

  --headerIndex: 101;
  --modalZIndex: calc(var(--headerIndex) + 10);
  --loaderZIndex: calc(var(--loaderIndex) + 10);

  --fontFamily: 'Poppins', sans-serif;
}

@media (min-width: 992px) {
  :root {
    --boxWidth: 96%;
    --boxHPadding: 16px;
    --articleVPadding: 60px;

    --buttonFontSize: 15px;
    --contBxHPadding: 40px;
  }
}

@media (min-width: 1400px) {
  :root {
    --boxWidth: 92%;
  }
}
