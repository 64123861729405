.slider {
  position: relative;
  overflow: hidden;
  line-height: 0;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 1;
}
.sliderInner {
  white-space: nowrap;
  line-height: normal;
  transition: transform 0.5s ease;
}
.item {
  line-height: normal;
  gap: 8px;
}
.item > picture {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: rgba(var(--black-rgb), 0.16);
}
.item > picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.item p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.indicators {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;
}
.indicatorsLeft {
  left: 0;
}
.indicatorsRight {
  right: 0;
}
.indicators button {
  width: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  line-height: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
