.contaniner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.box {
  position: relative;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  z-index: 12;
  background-color: #de8383;
  height: 500px;
}
.box h2 {
  font-size: 40px;
  color: black;
  margin-top: 20%;
  margin-left: 5%;
}
.box p {
  width: 50%;
  margin-left: 5%;
  font-weight: 400;
  font-size: 18px;
}
.box button {
  margin-left: 5%;
}
.box img {
  height: 500px;
}
