.article {
  padding-top: 32px;
  padding-bottom: 32px;
}
.contain {
  width: 100%;
  padding-right: var(--boxHPadding);
  padding-left: var(--boxHPadding);
  max-width: var(--boxWidth);
  margin: 0 auto;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
