.article {
  position: relative;
  background-color: #fff;
  padding-top: var(--articleVPadding);
  padding-bottom: var(--articleVPadding);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
  background: -webkit-gradient(
    linear left top,
    left bottom,
    color-stop(10%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.65))
  );
  margin-top: -60px;
}
.article::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
}
.box {
  position: relative;
  width: 100%;
  max-width: var(--boxWidth);
  margin-right: auto;
  margin-left: auto;
  padding: var(--boxHPadding);
  z-index: 12;
}
.content {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: left;
  height: calc(100vh - (72px + (var(--articleVPadding) * 2)));
}
.content h2 {
  font-size: 40px;
  max-width: 672px;
}
.para {
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  width: 350px;
}
.btn {
  width: 150px;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  margin-left: 5%;
  background-color: transparent;
}
