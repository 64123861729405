.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: var(--buttonFontSize);
  font-weight: var(--buttonFontWeight);
  line-height: var(--buttonLineHeight);
  height: var(--buttonHeight);
  padding: 6px 16px;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.button::disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
