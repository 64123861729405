.grid {
  display: flex;
  flex-wrap: wrap;
}
.content_stretch {
  align-content: stretch;
}
.grid_gap8 {
  margin: 0 calc((var(--gap8) / 2) * -1);
}
.grid_gap10 {
  margin: 0 calc((var(--gap10) / 2) * -1);
}
.grid_gap20 {
  margin: 0 calc((var(--gap20) / 2) * -1);
}
.grid_gap30 {
  margin: 0 calc((var(--gap30) / 2) * -1);
}
.grid_gap40 {
  margin: 0 calc((var(--gap40) / 2) * -1);
}
[class^='col'] {
  width: 100%;
}
.grid_gap8 [class^='col'] {
  padding: 0 calc(var(--gap8) / 2);
}
.grid_gap10 [class^='col'] {
  padding: 0 calc(var(--gap10) / 2);
}
.grid_gap20 [class^='col'] {
  padding: 0 calc(var(--gap20) / 2);
}
.grid_gap30 [class^='col'] {
  padding: 0 calc(var(--gap30) / 2);
}
.grid_gap40 [class^='col'] {
  padding: 0 calc(var(--gap40) / 2);
}
.colxs1 {
  width: 8.33%;
}
.colxs2 {
  width: 16.66%;
}
.colxs2p5 {
  width: 20%;
}
.colxs3 {
  width: 25%;
}
.colxs4 {
  width: 33.33%;
}
.colxs5 {
  width: 41.66%;
}
.colxs6 {
  width: 50%;
}
.colxs7 {
  width: 58.33%;
}
.colxs8 {
  width: 66.66%;
}
.colxs9 {
  width: 75%;
}
.colxs10 {
  width: 83.33%;
}
.colxs11 {
  width: 91.66%;
}
.colxs12 {
  width: 100%;
}

@media (min-width: 480px) {
  .colsm1 {
    width: 8.33%;
  }
  .colsm2 {
    width: 16.66%;
  }
  .colsm2p5 {
    width: 20%;
  }
  .colsm3 {
    width: 25%;
  }
  .colsm4 {
    width: 33.33%;
  }
  .colsm5 {
    width: 41.66%;
  }
  .colsm6 {
    width: 50%;
  }
  .colsm7 {
    width: 58.33%;
  }
  .colsm8 {
    width: 66.66%;
  }
  .colsm9 {
    width: 75%;
  }
  .colsm10 {
    width: 83.33%;
  }
  .colsm11 {
    width: 91.66%;
  }
  .colsm12 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .colmd1 {
    width: 8.33%;
  }
  .colmd2 {
    width: 16.66%;
  }
  .colmd2p5 {
    width: 20%;
  }
  .colmd3 {
    width: 25%;
  }
  .colmd4 {
    width: 33.33%;
  }
  .colmd5 {
    width: 41.66%;
  }
  .colmd6 {
    width: 50%;
  }
  .colmd7 {
    width: 58.33%;
  }
  .colmd8 {
    width: 66.66%;
  }
  .colmd9 {
    width: 75%;
  }
  .colmd10 {
    width: 83.33%;
  }
  .colmd11 {
    width: 91.66%;
  }
  .colmd12 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .collg1 {
    width: 8.33%;
  }
  .collg2 {
    width: 16.66%;
  }
  .collg2p5 {
    width: 20%;
  }
  .collg3 {
    width: 25%;
  }
  .collg4 {
    width: 33.33%;
  }
  .collg5 {
    width: 41.66%;
  }
  .collg6 {
    width: 50%;
  }
  .collg7 {
    width: 58.33%;
  }
  .collg8 {
    width: 66.66%;
  }
  .collg9 {
    width: 75%;
  }
  .collg10 {
    width: 83.33%;
  }
  .collg11 {
    width: 91.66%;
  }
  .collg12 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .colxl1 {
    width: 8.33%;
  }
  .colxl2 {
    width: 16.66%;
  }
  .colxl2p5 {
    width: 20%;
  }
  .colxl3 {
    width: 25%;
  }
  .colxl4 {
    width: 33.33%;
  }
  .colxl5 {
    width: 41.66%;
  }
  .colxl6 {
    width: 50%;
  }
  .colxl7 {
    width: 58.33%;
  }
  .colxl8 {
    width: 66.66%;
  }
  .colxl9 {
    width: 75%;
  }
  .colxl10 {
    width: 83.33%;
  }
  .colxl11 {
    width: 91.66%;
  }
  .colxl12 {
    width: 100%;
  }
}
