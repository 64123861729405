.zeward_dd .rs-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  background: transparent;
  height: auto;
  border-radius: 0;
  padding: 0;
  margin: 0;
  color: inherit;
}
.zeward_dd .rs-ripple-pond {
  background: transparent;
  display: none;
}
.zeward_dd .rs-dropdown-toggle-caret {
  position: unset;
  top: unset;
  right: unset;
  height: unset;
}
.zeward_dd .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
  margin: 0;
}
.zeward_dd .rs-dropdown .rs-btn > .rs-dropdown-toggle-icon {
  margin: 0;
}
.zeward_dd .rs-icon {
  width: 20px;
  height: auto;
}
.rs-slider-bar {
  background: #b2b2b2;
  height: 4px;
}
.rs-slider-progress-bar {
  height: 4px;
  background: linear-gradient(90deg, #01cda8 0%, #8d23dd 100%);
}
.rs-slider-handle {
  top: -9px;
}
.rs-slider-handle:before {
  width: 22px;
  height: 22px;
  border: 0;
  margin-left: -11px;
  background: #862cda;
}
.rs-slider-handle:focus:before,
.rs-slider-handle:hover:before {
  box-shadow: 0px 0px 0px 8px rgba(134, 44, 218, 0.25);
}

/* rs_modal */
.rs-modal {
  margin: 0;
  margin-left: auto;
}
.rs-modal-content {
  background: #ffffff;
  border-radius: 0;
  border: 0;
}
/* /rs_modal */
