.article {
  margin: 0;
}
.header {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 122px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(20deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
  z-index: -1;
}
.rowNor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}

.pageRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}
.pageRw > div:nth-of-type(1) {
  width: calc(50% - 50.5px);
  padding-top: var(--articleVPadding);
  padding-bottom: var(--articleVPadding);
}
.pageRw > div:nth-of-type(2) {
  width: 1px;
  background-color: var(--foreground-color);
  opacity: 0.16;
  align-self: stretch;
}
.pageRw > div:nth-of-type(3) {
  width: calc(50% - 50.5px);
  padding-top: var(--articleVPadding);
  padding-bottom: var(--articleVPadding);
}
.userBx {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 50px 32px;
  border-radius: 16px;
  z-index: 1;
}
.userBx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--foreground-color);
  opacity: 0.16;
  z-index: -1;
}

.emptyCartBx {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 560px;
  margin: 50px auto 0;
  text-align: center;
  padding: 50px 32px;
  border-radius: 16px;
  z-index: 1;
}
.emptyCartBx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: var(--foreground-color);
  opacity: 0.16;
  z-index: -1;
}

.titleBx {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.titleBx h2 {
  font-size: 30px;
  font-weight: 700;
}
.titleBx h6 {
  font-size: 16px;
  font-weight: 400;
}

.logTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
}
.logTabs button {
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 18px;
  font-weight: 700;
  color: rgba(63, 76, 80, 1);
  border: 0;
  border-bottom: transparent 2px solid;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.logTabs button.active {
  color: var(--primary);
  border-bottom: var(--primary) 2px solid;
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 14px 16px;
  z-index: 1;
}
.item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--foreground-color);
  opacity: 0.16;
  z-index: -1;
}
.item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--foreground-color);
  opacity: 0.24;
  z-index: -1;
}

.profile {
  display: flex;
  align-items: center;
  gap: 8px;
}
.profile picture {
  line-height: 0;
}
.profile img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}
.profile > div {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.itemTitle {
  font-size: 16px;
  font-weight: 600;
}
.rowF {
  display: flex;
  align-items: center;
  gap: 16px;
}
.rowF {
  font-size: 14px;
  font-weight: 600;
}
.profile button {
  font-size: 12px;
  font-weight: 500;
  color: var(--danger);
  display: flex;
  text-align: left;
  height: auto;
  line-height: normal;
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.profile button:hover {
  text-decoration: underline;
}
.item > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.qtyBx {
  width: 100px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.25px;
  background: var(--foreground-color);
  color: var(--background-color);
  border-radius: 24px;
}
.qtyBx button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22.5px;
  height: 22.5px;
  line-height: 0;
  padding: 0;
  background: var(--background-color);
  border: 0;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.qtyBx button svg {
  width: 10px;
  height: auto;
}
.qtyBx > div {
  width: 30px;
  text-align: center;
  margin: 0 8px;
}
.footer {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
}
.footer > div:nth-of-type(2) {
  font-size: 18px;
}
.button1 {
  width: 180px;
}
.b_checkout {
  width: 180px;
}
.b_link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.b_link > p {
  margin-left: 24px;
  margin-right: 8px;
}
.box1 {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pickupBx {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 14px 16px;
  z-index: 1;
}
.pickupBx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--foreground-color);
  opacity: 0.16;
  z-index: -1;
}
.pickup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-weight: 600;
}
.pickAddBx {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 21px 16px;
  z-index: 1;
}
.pickAddBx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: var(--foreground-color) 1px solid;
  border-bottom: var(--foreground-color) 1px solid;
  opacity: 0.24;
  z-index: -1;
}
.pickCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.pickCont > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
}
.pickCont > div:nth-of-type(2) small {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
}
.lineN {
  font-size: 14px;
}

@media (max-width: 991px) {
  .profile img {
    width: 28px;
    height: 28px;
  }
  .b_link {
    flex-direction: column;
    gap: 8px;
  }
  .b_link > p {
    margin-top: 16px;
  }
  .pageRw {
    flex-direction: column;
  }
  .pageRw > div:nth-of-type(1) {
    width: calc(100% - 0px);
    /* padding-top: var(--articleVPadding); */
    padding-bottom: 0;
  }
  .pageRw > div:nth-of-type(2) {
    width: 100%;
    height: 1px;
    /* align-self: stretch; */
  }
  .pageRw > div:nth-of-type(3) {
    width: calc(100% - 0px);
    padding-top: 0;
    /* padding-bottom: var(--articleVPadding); */
  }
}

@media (min-width: 992px) {
  .box_111 {
    padding: 30px 24px;
  }
}
