.carousel {
  position: relative;
  overflow: hidden;
  line-height: 0;
}
.carouselInner {
  white-space: nowrap;
  transition: transform 0.5s ease;
}
.carouselItem {
  display: inline-block;
  line-height: normal;
  /* transition: opacity 0.35s ease; */
}
.indicators {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
}
.indicators button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  line-height: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.indicators button svg {
  width: 8px;
  height: auto;
}
.indicators button:hover {
  opacity: 1;
}
.pageLabels {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
}
.pageLabels button {
  line-height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.pageLabels.horizontalStart button,
.pageLabels.horizontalCenter button,
.pageLabels.horizontalEnd button {
  width: 40px;
  height: 3px;
}

.pageLabels.verticalStart button,
.pageLabels.verticalCenter button,
.pageLabels.verticalEnd button {
  width: 3px;
  height: 40px;
}

.horizontalStart {
  flex-direction: row;
  justify-content: flex-start;
}
.horizontalCenter {
  flex-direction: row;
  justify-content: center;
}
.horizontalEnd {
  flex-direction: row;
  justify-content: flex-end;
}
.verticalStart {
  flex-direction: column;
  justify-content: flex-start;
}
.verticalCenter {
  flex-direction: column;
  justify-content: center;
}
.verticalEnd {
  flex-direction: column;
  justify-content: flex-end;
}
