.close {
  position: absolute;
  top: -16px;
  right: -16px;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  z-index: 12;
}
.closeOver {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0 0;
  z-index: 12;
}
.closeR {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.close_button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  margin: 0;
}
.close_button svg {
  width: 16px;
  height: auto;
}
.close .close_button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--foreground-color);
}
.close .close_button svg {
  width: 10px;
  height: auto;
}
.close_button svg,
.close_button path {
  fill: var(--background-color);
}
.closeR .close_button svg,
.closeR .close_button path {
  fill: var(--foreground-color);
}
.closeOver .close_button svg,
.closeOver .close_button path {
  fill: var(--foreground-color);
}
