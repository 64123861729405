.account_box {
  font-family: var(--fontPoppins);
  max-width: 586px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 13px;
  padding-left: 13px;
}
.account_box2 {
  font-family: var(--fontPoppins);
  max-width: 706px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 13px;
  padding-left: 13px;
}
.box3 {
  width: 100%;
  max-width: var(--boxWidth);
  padding-right: var(--boxHPadding);
  padding-left: var(--boxHPadding);
  margin: 0 auto;
}
.table1 {
  width: 100%;
  font-family: var(--fontPoppins);
  font-size: 16px;
  color: var(--dark);
}
.table1 thead {
  background-color: var(--accentbg);
}
.table1 th {
  color: var(--gray);
  padding: 16px 10px;
  text-align: left;
  border-bottom: var(--white) 1px solid;
}
.table1 th.right {
  text-align: right;
}
.table1 td {
  text-align: left;
}
.no_data1 {
  font-size: 12px;
  background-color: var(--gray);
  color: var(--white);
  border-radius: 4px;
  padding: 10px 16px;
}
.ofx_auto {
  overflow: hidden;
  overflow-x: auto;
}
.or {
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #9a9c9f;
}
.or::before,
.or::after {
  content: '';
  width: 100px;
  height: 2px;
  background-color: #d9d9d9;
}
.or::before {
  margin-right: 8px;
}
.or::after {
  margin-left: 8px;
}

/* loading */
.loading1 {
  position: relative;
  left: -35px;
  width: 120px;
  height: 14px;
  border-radius: 0 0 15px 15px;
  background-color: var(--dark);
  box-shadow: 0 -1px 4px #5d6063 inset;
  animation: panex 0.5s linear alternate infinite;
  transform-origin: 170px 0;
  z-index: 10;
  perspective: 300px;
}
.loading1::before {
  content: '';
  position: absolute;
  left: calc(100% - 2px);
  top: 0;
  z-index: -2;
  height: 10px;
  width: 70px;
  border-radius: 0 4px 4px 0;
  background-repeat: no-repeat;
  background-image: linear-gradient(var(--gray), var(--dark)),
    linear-gradient(var(--gray) 24px, transparent 0),
    linear-gradient(var(--gray) 24px, transparent 0);
  background-size: 50px 10px, 4px 8px, 24px 4px;
  background-position: right center, 17px center, 0px center;
}
.loading1::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -2;
  transform: translate(-50%, -20px) rotate3d(75, -2, 3, 78deg);
  width: 55px;
  height: 53px;
  background: #fff;
  background-image: radial-gradient(circle 3px, #fff6 90%, transparent 10%),
    radial-gradient(circle 12px, #ffc400 90%, transparent 10%),
    radial-gradient(circle 12px, #ffae00 100%, transparent 0);
  background-repeat: no-repeat;
  background-position: -4px -6px, -2px -2px, -1px -1px;
  box-shadow: -2px -3px #0002 inset, 0 0 4px #0003 inset;
  border-radius: 47% 36% 50% 50% / 49% 45% 42% 44%;
  animation: eggRst 1s ease-out infinite;
}

.icon {
  line-height: 0;
}

@media (max-width: 991px) {
  .table1 {
    font-size: 16px;
  }
  .table1 thead {
    display: none;
  }
  .table1 tbody tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    background-color: var(--white);
    padding: 8px 4px;
    margin-bottom: 2px;
  }
  .table1 td {
    padding: 0 4px;
    border-top: 0;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .table1 td::before {
    content: attr(data-foo);
    font-size: 12px;
    font-weight: 600;
    color: var(--gray);
    text-transform: uppercase;
  }
  .table1 td .hlite {
    font-size: 17px;
  }
}

@media (min-width: 992px) {
  .table1 td {
    padding: 13px 10px;
    background-color: var(--accentbg);
    border-top: var(--white) 1px solid;
    border-bottom: var(--white) 1px solid;
  }
  .table1 td.right {
    text-align: right;
  }
  .table1 td.right button {
    margin-left: auto;
  }
  .table1 td .hlite {
    font-size: 18px;
  }
}

@keyframes eggRst {
  0%,
  100% {
    transform: translate(-50%, -20px) rotate3d(90, 0, 0, 90deg);
    opacity: 0;
  }
  10%,
  90% {
    transform: translate(-50%, -30px) rotate3d(90, 0, 0, 90deg);
    opacity: 1;
  }
  25% {
    transform: translate(-50%, -40px) rotate3d(85, 17, 2, 70deg);
  }
  75% {
    transform: translate(-50%, -40px) rotate3d(75, -3, 2, 70deg);
  }
  50% {
    transform: translate(-55%, -50px) rotate3d(75, -8, 3, 50deg);
  }
}
@keyframes panex {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
/* /loading */
