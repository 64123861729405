.header {
  padding: 40px 0 30px;
}
.contain {
  width: 100%;
  padding-right: var(--boxHPadding);
  padding-left: var(--boxHPadding);
  max-width: var(--boxWidth);
  margin: 0 auto;
}
.headRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.totalRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.countRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.countRow > svg {
  width: 62px;
  height: auto;
}
.small {
  font-size: 13px;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .headRow {
    flex-direction: column;
    gap: 16px;
  }
  .totalRow {
    gap: 16px;
  }
}
