.box1 {
  padding: 0;
}

.mainItem {
  border: var(--borderColor) 0.5px solid;
  border-radius: 8px 8px;
}
.itemHeader {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
  border-bottom: var(--borderColor) 0.5px solid;
  border-radius: 8px 8px 0 0;
}
.orderBrand {
  display: flex;
  align-items: center;
  gap: 8px;
}
.alignRight {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;
  text-align: right;
}
.alignRight > div:nth-of-type(1) {
  width: 100%;
}
.itemBody {
  padding: 11px;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.menuItem > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  max-width: 60%;
}
.menuItem > div:nth-of-type(1) img {
  width: 70px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}
.menuItem > div:not(:nth-of-type(1)) {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.menuItem p {
  font-size: 12px;
}
.menuItem > div:last-child {
  text-align: right;
}

@media (max-width: 991px) {
  .itemHeader {
    flex-wrap: wrap;
  }
  .itemHeader > div:nth-of-type(1) {
    width: 100%;
  }
  .orderBrand {
    flex-wrap: wrap;
    gap: 0 8px;
  }
  .orderBrand > div:nth-of-type(3) {
    display: none;
  }
  .orderBrand > div:nth-of-type(4) {
    width: 100%;
  }
  .alignRight {
    gap: 0 5px;
  }
  .itemBody {
    padding: 16px;
  }
  .menuItem {
    flex-wrap: wrap;
  }
  .menuItem > div:nth-of-type(1) {
    width: 100%;
    max-width: 100%;
  }
  .menuItem > div:nth-of-type(1) img {
    width: 50px;
  }
  .menuItem > div:not(:nth-of-type(1)) {
    gap: 0px;
  }
}
