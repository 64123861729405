.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.header {
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 13px 21px;
  /* background: rgba(165, 97, 216, 0.08); */
  background: #f8f3fc;
  border-radius: 4px 4px 0px 0px;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
}
.header > div:nth-of-type(1) h6 {
  font-weight: 500;
}
.header > div:nth-of-type(1) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.zewards svg {
  width: 21px;
  height: auto;
}
.zewards > div:nth-of-type(1) {
  color: #a561d8;
}
.perPoint {
  font-weight: 700;
  font-size: 13px;
  background: #ffffff;
  color: #a561d8;
  padding: 8px 19px;
  border-radius: 24px;
  text-transform: uppercase;
}
.body {
  background: #ffffff;
  padding: 20px 21px 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 0px 0px 4px 4px;
}
.body > div:nth-of-type(2) {
  padding-right: 11px;
  padding-left: 11px;
}
.body h3 {
  font-weight: 300;
  font-size: 32px;
  line-height: 130%;
  color: #62748f;
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 3px;
}
.body h3 svg {
  width: 22px;
  height: auto;
}
