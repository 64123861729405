.header {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.header > div {
  width: 100%;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
}
.header label {
  color: rgb(var(--white-rgb));
}
.header_box_steps {
  width: calc(100% - 40px);
  max-width: 444px;
}
.article {
  width: 100%;
  max-width: 444px;
}
.banner_1 {
  border-radius: 8px 8px 0 0;
}
.banner_1 picture {
  line-height: 0;
}
.banner_1 img {
  width: 100%;
  height: 144px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}
.box_1 {
  padding: 36px 40px 40px;
}
.box_steps {
  padding-right: 12px;
  padding-left: 12px;
}

.para_agree1 {
  font-size: 12px;
  color: inherit;
  text-align: center;
  line-height: 130%;
}
.b_link button {
  display: inline-block;
}
