.acc_ele {
  padding-top: 42px;
  box-shadow: 0 -1px 0 var(--gray);
  border-radius: 10px 10px 0 0;
}
.acc_button {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  color: inherit;
}
.acc_button h5 {
  line-height: 1.1;
}
.icon_acc {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 0;
  outline: 0;
  padding: 0;
  box-shadow: none;
}
.icon_acc svg {
  fill: var(--gray);
}
.children_acc {
  font-size: 16px;
  margin-top: 24px;
}
