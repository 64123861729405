.brand_banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 122px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.brand_banner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(20deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
  z-index: -1;
}
.bb_flex1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}
.wrap_1 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.box_menu1 {
  width: calc(100% - 0px);
}
.type_item h5 {
  padding-top: 66px;
}
.xwrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 24px;
}
.xy_wrap button + button {
  margin: 0;
}
.xy_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.button_item {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  border-radius: 8px;
  padding: 0;
  position: relative;
  text-align: left;
}
.button_item > div {
  position: relative;
  width: 100%;
  display: flex;
  line-height: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  z-index: 1;
}
.button_item picture {
  width: 100%;
}
.button_item picture a {
  width: 100%;
  display: flex;
  line-height: 0;
}
.button_item picture img {
  width: 100%;
  height: 174px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  transition: transform 0.35s ease;
}
.button_item picture:hover img {
  transform: scale(1.35);
}
.bAddItem {
  position: absolute;
  right: 20px;
  bottom: 25px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--foreground-color);
  border: var(--background-color) 1.68px solid;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.qtyBx {
  position: absolute;
  right: 20px;
  bottom: 25px;
  width: 100px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.25px;
  background: var(--foreground-color);
  color: var(--background-color);
  border-radius: 24px;
}
.qtyBx button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22.5px;
  height: 22.5px;
  line-height: 0;
  padding: 0;
  background: var(--background-color);
  border: 0;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.qtyBx button svg {
  width: 10px;
  height: auto;
}
.qtyBx > div {
  width: 30px;
  text-align: center;
  margin: 0 8px;
}
.bItemCont {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px 16px 24px;
  width: 100%;
  background: var(--background-color);
  border-radius: 5px 5px 8px 8px;
  margin-top: -5px;
  z-index: 2;
}
.bItemTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--foreground-color);
  opacity: 0.8;
  line-height: normal;
  letter-spacing: normal;
}
.bItemPrice {
  font-size: 15px;
  font-weight: 600;
  color: var(--foreground-color);
  opacity: 0.6;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 4px;
}
.buttonNutritional {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 4px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(42, 89, 84, 1);
  background: linear-gradient(94deg, #61ccf8 5.53%, #cbed74 94.47%);
  border: 0;
  border-radius: 60px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.brand_xwrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 4px;
}
.brand_xwrap button + button {
  margin: 0;
}
.brand_tab {
  width: 164px;
  height: 121px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  outline: 0;
  box-shadow: none;
  background: #ffffff;
  color: #2a2f31;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  border: 0.5px solid #9a9c9f;
  border-radius: 0;
}
.brand_tab.active {
  background: var(--primary);
  color: #ffffff;
  border-color: var(--primary);
}
.brand_tab img {
  height: 57.48px;
  width: auto;
  max-width: 100%;
}

.modalBx {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modal_header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.modal_header > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addITr {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: var(--foreground-color);
  z-index: 1;
}
.addITr + .addITr {
  margin-top: 1px;
}
.addITr + .addITr::before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border-top: var(--foreground-color) 1px solid;
  opacity: 0.24;
}
.addITr p {
  font-size: 13px;
  font-weight: 500;
}
.check_name {
  display: flex;
  align-items: center;
  gap: 8px;
}
.check_name > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.check_name input {
  width: 16px;
  height: 16px;
}
.check_name h6 {
  font-size: 14px;
}
.modal_footer {
  margin: 0;
}
.modal_footer button {
  padding-right: 16px;
  padding-left: 16px;
}

.scroll_links {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  gap: 60px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-top: 26px;
  background: var(--background-color);
  z-index: 12;
}
.scroll_link_item {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 4px 0;
  text-transform: uppercase;
  color: var(--foreground-color);
  opacity: 0.6;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.scroll_link_item.active {
  opacity: 1;
  border-bottom: 3px solid var(--primary);
}
.twoCharacter {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--white);
  text-transform: uppercase;
  border-radius: 50%;
}
.active .twoCharacter {
  background-color: var(--white);
  color: var(--primary);
}

.nutritionalModalBx {
  width: 100vw;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
}

@media (max-width: 479px) {
  .button_item {
    width: 100%;
  }
}
