.title {
  text-align: center;
  padding: 8px 12px;
  color: var(--white);
  background: var(--gray);
  border-radius: 8px 8px 0 0;
}
.orderBx {
  width: 100%;
  max-height: calc(100vh - 310px);
  overflow-y: auto;
}
.order_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.item_container {
  position: relative;
  background: var(--foreground-color);
  color: var(--background-color);
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
}
.item_container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: var(--background-color) 1px solid;
  opacity: 0.16;
  z-index: -1;
}

.item_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.item_header > div {
  display: flex;
  align-items: center;
}

.item_header img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.item_header_title {
  font-size: 14px;
  font-weight: 500;
}

.item_header_title img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
}

.item_header_price {
  font-size: 15px;
  font-weight: 700;
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
}

.item_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
}

.item_info div {
  margin-right: 20px;
}

.item_info span {
  padding: 2px 10px;
  font-weight: 600;
  background: var(--gray);
  border-radius: 5px;
  margin-left: 5px;
}

.modifiers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.modifiers_heading {
  font-size: 14px;
  font-weight: 500;
  width: 50%;
}

.modifiers_price {
  font-size: 14px;
  font-weight: 600;
}

.remove_button {
  font-size: 12px;
  font-weight: 500;
  color: var(--danger);
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.empty_state {
  padding: 14px 16px;
  margin: 20px;
  font-size: 14px;
  font-weight: 400;
  background: var(--accentbg);
  border-radius: 5px;
  color: var(--gray);
}

.checkout_button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.checkout_button {
  background: var(--primary);
  color: var(--white);
  font-size: 20px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
}
