.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: var(--buttonFontSize);
  font-weight: var(--buttonFontWeight);
  height: auto;
  padding: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
