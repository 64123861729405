.mt_4 {
  margin-top: 4px;
}
.mt_5 {
  margin-top: 5px;
}
.mt_10 {
  margin-top: 10px;
}
.mt_15 {
  margin-top: 15px;
}
.mt_16 {
  margin-top: 16px;
}
.mt_20 {
  margin-top: 20px;
}
.mt_25 {
  margin-top: 25px;
}
.mt_30 {
  margin-top: 30px;
}
.mt_35 {
  margin-top: 35px;
}
.mt_40 {
  margin-top: 40px;
}
.mt_8 {
  margin-top: 8px;
}
.pt_5 {
  padding-top: 5px;
}
.pt_10 {
  padding-top: 10px;
}
.pt_15 {
  padding-top: 15px;
}
.pt_20 {
  padding-top: 20px;
}
.pt_25 {
  padding-top: 25px;
}
.pt_30 {
  padding-top: 30px;
}
.pt_35 {
  padding-top: 35px;
}
.pt_40 {
  padding-top: 40px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex: 1;
}
.box {
  width: 100%;
  max-width: var(--boxWidth);
  padding: 0 var(--boxHPadding);
  margin: 0 auto;
}
.full {
  width: 100%;
}
.t_up {
  text-transform: uppercase;
}
.t_underline {
  text-decoration: underline;
}
.alink.t_underline:hover {
  text-decoration: none;
}
.align_center,
.t_center {
  text-align: center;
}
.flex {
  display: flex;
}
.fwrap {
  flex-wrap: w;
}
.item_vcenter {
  align-items: center;
}
.item_vend {
  align-items: flex-end;
}
.item_hcenter {
  justify-content: center;
}
.item_hend {
  justify-content: flex-end;
}
.item_space_btn {
  justify-content: space-between;
}
.item_space_and {
  justify-content: space-around;
}
.item_space_evn {
  justify-content: space-evenly;
}

.badge {
  background-color: var(--primary);
  border-radius: 4px;
  padding: 2px 8px;
  color: var(--white);
}
