.grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.radio {
  position: relative;
}
.radio input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.radio span {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: #8d23dd 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.radio input:checked + span::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #8d23dd;
}
.label {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #4a5568;
}
