.header {
  background: #ebf2fa;
}
.contain {
  width: 100%;
  padding-right: var(--boxHPadding);
  padding-left: var(--boxHPadding);
  max-width: var(--boxWidth);
  margin: 0 auto;
}
.hGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 86px;
}
.hGrid > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.hGrid > div:nth-of-type(2) button {
  width: 158px;
}
.body {
  padding: 40px 0;
  max-width: 879px;
  margin-right: auto;
  margin-left: auto;
}
.bGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}
.bGrid > div:nth-of-type(1) {
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.bGrid > div:nth-of-type(2) {
  width: 144px;
}
.totalBox {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  background: #a561d8;
  border-radius: 6px;
  padding: 32px 17px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.totalBox p {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.totalBox p svg {
  width: 12px;
  height: auto;
}
.totalBox h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
}
.totalBox h3 {
  font-weight: 300;
  font-size: 32px;
  line-height: 130%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.totalBox h3 svg {
  width: 22px;
  height: auto;
}
.totalBox strong {
  font-weight: 500;
}
.totalBox i {
  font-style: italic;
}
.totalBox button {
  width: 100%;
  height: 38px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #a561d8;
  border: 0;
  outline: none;
  box-shadow: none;
  border-radius: 24px;
}
