.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons button {
  width: 120px;
}
.search_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_box > div:first-child {
  width: 360px;
}
.search_box button {
  width: 200px;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 16px;
  height: var(--inputHeight);
}
.xwrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 24px;
}
.xwrap button + button {
  margin: 0;
}
.button_item {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 273px;
  height: auto;
  background: var(--background-color);
  color: var(--foreground-color);
  border: transparent 0.5px solid;
}
.button_item:hover {
  border: var(--primary) 0.5px solid;
}
.button_item picture {
  position: relative;
  width: 100%;
  padding-top: 70%;
}
.button_item img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.b_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: var(--body-font-family);
  width: 100%;
  padding: 10px;
  border: 0.5px solid var(--primary);
}
.b_content h6 {
  font-size: 14px;
}
.box1 {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}
