.article {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 24px;
}
.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background: var(--white);
    padding: 16px 30px;
    border-radius: 5px;
}
.item h5 {
    line-height: 1;
}
.subTitle {
    font-size: 12px;
    color: var(--foreGroundGray);
    text-transform: uppercase;
    line-height: 1;
}
.zewardsCol {
    display: flex;
    align-items: center;
    gap: 16px;
}
.zewardsCol > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.zewardsCol h4 {
    line-height: 1;
}
.aquiredOn {
    font-size: 10px;
    color: var(--foreGroundGray);
    font-weight: 500;
    line-height: 1;
}
.zewardsCol > svg {
    width: 45px;
    height: auto;
}