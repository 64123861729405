.contaniner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.article {
  position: relative;
  background-color: #fff;
  padding-top: var(--articleVPadding);
  padding-bottom: var(--articleVPadding);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* clip-path: polygon(2% 2%, 100% 0%, 100% 93%, 0 100%); */
  align-items: center;
  justify-content: center;
}
.block {
  position: relative;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  z-index: 12;
  background-color: #eeeeee;
  height: 500px;
}
.head {
  justify-content: center;
  align-items: center;
  display: grid;
}
.line {
  background-color: #de8383;
  width: 100px;
  height: 8px;
  align-self: center;
  margin-left: 200px;
}
.fooditems {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.box {
  width: 40%;
  float: left;
  justify-content: center;
  margin: auto;
}
.box h2 {
  font-size: 30px;
}
.box p {
  font-weight: 400;
  color: #2a2f31;
}
.btn {
  width: 150px;
  align-items: center;
  justify-content: center;
  border: 2px solid #f0616e;
  margin-left: 0%;
  background-color: #f0616e;
}
.btn_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
.btn_view_more {
  border-bottom: 2px solid #de8383;
  width: 120px;
  align-items: center;
  justify-content: center;
  height: 28px;
  text-align: center;
  cursor: pointer;
}
