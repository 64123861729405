.header {
  background: #ebf2fa;
  padding: 16px 0;
}
.contain {
  width: 100%;
  padding-right: var(--boxHPadding);
  padding-left: var(--boxHPadding);
  max-width: var(--boxWidth);
  margin: 0 auto;
}
.headRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.headAmount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.icon {
  line-height: 0;
}

.article {
  padding-top: 32px;
  padding-bottom: var(--articleVPadding);
  background: var(--white);
}
.grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}
.grid > div:nth-of-type(1) {
  width: calc(50% - 24px);
  max-width: 526px;
}
.grid > div:nth-of-type(2) {
  width: 50%;
}
.payAbleItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 13px 21px;
  background: #f4f7fc;
  border-radius: 12px;
}
.flags {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.flag {
  line-height: 0;
}
.flag img {
  width: 41px;
  height: 29px;
  object-fit: contain;
  background: #ffffff;
}
.orderList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.orderItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 21px;
  gap: 16px;
  background: #ebf2fa;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1a202c;
}
.itemInfo {
  gap: 8px;
}
.itemInfo,
.itemTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.itemTotal {
  gap: 16px;
}
.itemImg {
  display: flex;
  line-height: 0;
}
.itemImg img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}
.itemPrice {
  text-align: right;
  font-weight: 600;
}
.itemQuantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;
  gap: 19px;
  width: 100px;
  height: 34px;
  background: #ffffff;
  border-radius: 25px;
  font-weight: 400;
}
.calculateRow {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 0 63px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.calculateRow > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.grandTotal {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.calculateZeward > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a561d8;
}
.rewardAmount {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #a561d8;
}

.card {
  width: 100%;
  height: var(--inputHeight);
  line-height: var(--inputLineHeight);
  padding-top: 10px;
  padding-right: var(--inputHPadding);
  padding-bottom: 10px;
  padding-left: var(--inputHPadding);
  background-color: var(--inputBackground);
  color: var(--inputColor);
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  transition: all 0.2s ease;
}
.button1 {
  width: 151px;
  padding-right: 20px;
  padding-left: 20px;
}
.button1:disabled {
  opacity: 0.5;
  box-shadow: none;
  cursor: not-allowed;
}
.button1:disabled:hover {
  box-shadow: none;
}

.zewards {
  background: #f4f7fc;
  border-radius: 4px;
  padding: 30px;
}
.header_zeward {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.header_zeward > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.body_zeward {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
}
.body_zeward > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.body_zeward > div:nth-of-type(1) svg {
  width: 45px;
  height: auto;
}
.h1 {
  font-weight: 300;
  font-size: 96px;
  line-height: 130%;
  color: #62748f;
}
.body_zeward > div:nth-of-type(2) {
  width: 100%;
}
.footer_zeward {
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  color: #62748f;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px 17px 22px;
}
.count_rewards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
.count_rewards > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 13px;
  background: #f7fafc;
  border-radius: 6px;
}
.count_rewards > div:nth-of-type(1) {
  width: calc(66.666% - 3.5px);
}
.count_rewards > div:nth-of-type(2) {
  width: calc(33.333% - 3.5px);
}
.count_rewards > div > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.branchZeward {
  min-width: 114px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 6px 7px;
  background: #a561d8;
  color: #ffffff;
  border-radius: 18px;
}
.branchZeward label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
