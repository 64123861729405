.article {
  padding-top: 30px;
  padding-bottom: 60px;
}
.wrap_1 {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.img_bx {
  width: calc(50% + 54px);
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.img_bx picture {
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.img_bx img {
  width: 100%;
  height: auto;
  transition: transform 0.35s ease;
}
.img_bx img:hover {
  transform: scale(1.35);
}

.content {
  width: calc(50% - 86px);
}
.groups {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.group_bx {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.modi_bx {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modi_title {
  font-size: 16px;
}

.more_info {
  width: 100%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}

.box_allergens {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.allergens {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.allergens > div {
  width: calc(25% - 18px);
}
.allergens_item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.allergens_value {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}
.grid_1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.grid_1 > div {
  flex: 1;
}
.col_nutrional {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.nutrionals {
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-height: 350px;
  overflow-y: auto;
}
.nutrional_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0;
  border-top: var(--borderColor) 1px solid;
  border-bottom: var(--borderColor) 1px solid;
  margin-top: -1px;
}
.nutrional_item > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  gap: 8px;
}
.col_health {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.niBx {
  background: rgba(0, 0, 0, 0.03);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalBx {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modal_header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.modal_header > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addITr {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  z-index: 1;
}
.addITr + .addITr {
  margin-top: 1px;
}
.addITr + .addITr::before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border-top: var(--foreground-color) 1px solid;
  opacity: 0.24;
}
.addITr p {
  font-size: 13px;
  font-weight: 500;
}
.check_name {
  display: flex;
  align-items: center;
  gap: 8px;
}
.check_name > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.check_name input {
  width: 16px;
  height: 16px;
}
.check_name h6 {
  font-size: 14px;
}
.modal_footer {
  margin: 0;
}
.modal_footer button {
  padding-right: 16px;
}

.itemQuantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.itemQuantity > button {
  background: #999999;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
}
.itemQuantity > div {
  color: var(--foreground-color);
  font-weight: 600;
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: #DDDDDD 1px solid;
}

@media (max-width: 991px) {
  .wrap_1 {
    flex-direction: column;
  }
  .img_bx {
    width: calc(100% + 0px);
    padding: 21px;
  }
  .content {
    width: calc(100% - 0px);
  }
}