.article {
  padding: 0;
}
.order_types {
  text-align: center;
  max-width: 860px;
  padding: 104px 0 88px;
  margin: 0 auto;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.buttons button {
  width: 160px;
}
