.article {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}
.layout1 {
  position: relative;
  z-index: 1;
}
.layout1 picture {
  display: flex;
  line-height: 0;
}
.layout1 > picture img {
  width: 100%;
  height: auto;
}
.layout1 h1 {
  color: inherit;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 24;
}
.contentInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 460px;
}

.layout2 {
  position: relative;
  z-index: 1;
}
.layout2 .contentInner {
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
