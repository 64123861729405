.fetching {
  width: 28px;
  height: 28px;
  margin-top: 1.5px;
  border: 3px solid var(--primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.fetching::after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  left: 8px;
  top: 14px;
  border: 8px solid transparent;
  border-right-color: var(--primary);
  transform: rotate(-40deg);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
