button {
  outline: none;
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.b_sm {
  font-family: var(--fontBold);
  font-size: 9px;
  text-transform: uppercase;
  height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 4px;
}
.button1 {
  font-weight: 600;
  background: var(--primary);
  color: var(--white);
}
.button2 {
  background: transparent;
  border: 1.5px solid var(--primary);
  color: var(--primary);
}
.button2.active {
  background-color: var(--primary);
  color: var(--white);
}
.button3 {
  background: var(--white);
  border: 1px solid var(--borderColor);
  color: var(--textColor);
}
.button4 {
  background: var(--gray);
  color: var(--white);
}
.button5 {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: normal;
  height: 24px;
  padding-right: 12px;
  padding-left: 12px;
  border: var(--primary) 0.5px solid;
  color: var(--primary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.button5 svg {
  width: 9px;
  height: auto;
  fill: var(--primary);
}
.button6 {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.06em;
  height: 24px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.button6 svg {
  width: 16px;
  height: auto;
  fill: var(--white);
}
.button7 {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary);
  padding: 0;
  background-color: transparent;
  line-height: normal;
  height: auto;
}
.alink {
  display: inline-flex;
  height: auto;
  background-color: transparent;
  color: var(--primary);
  padding: 0;
  border-radius: 0;
}
.b_normal {
  padding-right: 24px;
  padding-left: 24px;
}
.button_link {
  background: transparent;
  margin: 0;
  display: flex;
}

@media (max-width: 991px) {
  .button5 {
    padding-right: 8px;
    padding-left: 8px;
    gap: 4px;
  }
}
