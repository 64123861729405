.article {
    background: var(--primary);
    padding: 4px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.inputCtrl {
    min-width: 260px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 24px;
    z-index: 1;
}
.input {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 4px 32px 4px 28px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 0;
    outline: none;
    box-shadow: none;
}
.textarea {
    width: 100%;
    font-family: var(--body-font-family);
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    background: transparent;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: var(--borderColor) 1px solid;
    outline: none;
    box-shadow: none;
    resize: none;
}
/* .input {
    color: var(--foreground-color);
} */
.input::-webkit-input-placeholder {
    color: var(--inputPlaceholderColor);
}
.input:-moz-placeholder {
    color: var(--inputPlaceholderColor);
    opacity: 1;
}
.input::-moz-placeholder {
    color: var(--inputPlaceholderColor);
    opacity: 1;
}
.input:-ms-input-placeholder {
    color: var(--inputPlaceholderColor);
}
.input::-ms-input-placeholder {
    color: var(--inputPlaceholderColor);
}
.input::placeholder {
    color: var(--inputPlaceholderColor);
}
.addressIcon {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.addressIcon svg {
    width: 14px;
    height: auto;
}
.locateBtn {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.buttonLocate {
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.btnCtrl {
    width: 129px;
    position: relative;
    border-radius: 24px;
    z-index: 1;
}
.valButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 4px 12px;
    background: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 24px;
}
.valButton > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.listBtnDd {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    top: 100%;
    left: -4px;
    right: -4px;
    background: var(--primary);
    padding: 4px;
    border-radius: 18px 18px;
}
.listButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 24px;
}

.autoCompleteDd {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
}

.mdlHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
}
.mdlHeader h4 {
    font-weight: 700;
}
.mdlFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
}
.mdlFooter > div:nth-of-type(1) {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.mdlFooter h6 {
    font-weight: 600;
}

@media (max-width: 991px) {
    .article {
        width: 100%;
        gap: 8px;
    }
    .inputCtrl {
        flex: 1;
        min-width: calc(100% - 124px);
    }
    .btnCtrl {
        width: 116px;
    }
    .valButton {
        padding: 4px 8px;
    }
    .valButton > div svg {
        width: 20px;
        height: auto;
    }
    .listButton {
        padding: 4px 8px;
    }
    .listButton svg {
        width: 20px;
        height: auto;
    }
}