.field_box + .field_box {
    margin-top: 16px;
}
.labelBox {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.inputbox {
    position: relative;
}
.required {
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translate(-100%, -50%);
    width: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: var(--danger);
}

.input {
    font-size: var(--inputFontSize);
    font-weight: 400;
    width: 100%;
    height: var(--inputHeight);
    line-height: var(--inputLineHeight);
    padding-right: var(--inputHPadding);
    padding-left: var(--inputHPadding);
    background-color: var(--background-color);
    color: var(--foreground-color);
    border: var(--inputBorder);
    border-radius: var(--inputRadius);
    outline: 0;
    transition: all 0.2s ease;
}

.input::-webkit-input-placeholder {
    color: var(--inputPlaceholderColor);
}
.input:-moz-placeholder {
    color: var(--inputPlaceholderColor);
    opacity: 1;
}
.input::-moz-placeholder {
    color: var(--inputPlaceholderColor);
    opacity: 1;
}
.input:-ms-input-placeholder {
    color: var(--inputPlaceholderColor);
}
.input::-ms-input-placeholder {
    color: var(--inputPlaceholderColor);
}
.input::placeholder {
    color: var(--inputPlaceholderColor);
}
  
.input:read-only {
    background-color: var(--background-color);
    color: var(--foreground-color);
    cursor: not-allowed;
}

.label {
    font-size: 14px;
    color: inherit;
}
.infobox {
    position: relative;
    padding: 2px;
    z-index: 1;
}
.infoChild {
    position: absolute;
    top: 0;
    left: 40px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    z-index: -10;
}
.infoChild svg {
    margin: 4px 4px 0 0;
}
.infoContent {
    width: 264px;
    background-color: var(--foreground-color);
    color: var(--background-color);
    padding: 16px;
    border-radius: var(--bgRadius);
}
.infobox:hover .infoChild {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}
.error {
    font-size: 11px;
    line-height: 12px;
    color: var(--danger);
    margin-top: 8px;
}
  