.footer {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}

.layout1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.socials a {
  line-height: 0;
  display: flex;
}
.socials a svg {
  width: 20px;
  height: auto;
}

.barBx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.barBxLayout2 {
  flex-direction: row-reverse;
}

.mobileView .barBx {
  flex-direction: column;
  gap: 8px;
}
.mobileView .barBxLayout2 {
  flex-direction: column-reverse;
}

@media (max-width: 991px) {
  .barBx {
    flex-direction: column;
    gap: 8px;
  }
  .barBxLayout2 {
    flex-direction: column-reverse;
  }
}
