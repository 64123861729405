.article {
  width: 100%;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 21px;
  border-radius: 13px 13px 0px 0px;
}
.header > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #ffffff;
}
.header > div:nth-of-type(1) h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.body {
  border-radius: 0 0 13px 13px;
  padding: 21px;
}
