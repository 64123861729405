.steps_1 {
  position: relative;
}
.steps_hbar1 {
  position: absolute;
  height: 4px;
  right: 16px;
  left: 16px;
  top: 32px;
  background-color: rgb(229 231 235);
}
.steps_progress {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.steps_indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.indicator_circle {
  height: 20px;
  width: 20px;
  margin-top: 4px;
  border: #e5e7eb 2px solid;
  border-radius: 9999px;
  background-color: rgb(var(--white-rgb));
  transition: border 0.3s ease-in;
}
.active .indicator_circle {
  border-color: var(--primary);
}
.indicator_badge {
  background-color: var(--primary);
  color: var(--white);
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
  position: relative;
  margin-top: 6px;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.active .indicator_badge {
  opacity: 1;
}
.indicator_uptriangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--primary);
  position: absolute;
  left: 50%;
  top: -5px;
  transform: translate(-50%);
}
