* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style-type: none;
  box-sizing: border-box;
}
html {
  margin: 0;
  padding: 0;
}
body {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  overflow-x: hidden;
}
html,
body {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--header-font-family);
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.flight {
  font-weight: 300;
}
strong,
.strong {
  font-weight: 500;
}
.fsemi {
  font-weight: 600;
}
b {
  font-weight: 700;
}
h1 {
  font-size: 56px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5,
.h5 {
  font-size: 20px;
}
h6,
.h6 {
  font-size: 16px;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}
a,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}
a,
a:focus,
a:hover {
  color: var(--primary);
}
a:visited {
  color: var(--primary);
}
button {
  font-family: var(--button-font-family);
}
picture {
  line-height: 0;
  display: flex;
}
img {
  width: 100%;
  height: auto;
  border: 0;
}
hr {
  border: 0;
  border-top: var(--foreground-color) 1px solid;
  opacity: 0.24;
}
[color='accent1'] {
  color: var(--primary);
}
.no_data {
  font-size: 13px;
  font-weight: 500;
  color: var(--foreGroundGray);
}
.rtf {
  margin: 0;
}
.rtf--mb__c {
  padding: 0;
  margin: 0;
}
.alice-carousel__stage {
  margin: 0;
}

/* foo_nav */
.foo_nav {
  display: flex;
  flex-direction: column;
}
.foo_nav a {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: var(--foreGroundGray);
}
.foo_nav a.active {
  color: var(--foreGround);
  font-weight: 500;
}
/* /foo_nav */

/* mainNav */
.main_nav a {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--foreGroundGray);
}
.main_nav a::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 0;
  height: 3px;
  background-color: var(--primary);
  transform: translateX(-50%);
  transition: width 0.35s ease;
}
.main_nav a.active {
  color: var(--foreGround);
}
.main_nav a.active::after,
.main_nav a:hover::after {
  width: 100%;
}
/* /mainNav */

[color='primary'] {
  color: var(--primary);
}

/* react-phone-number-input/style.css */
.CustomPhoneInput {
  position: relative;
}
.CustomPhoneInput .PhoneInputCountry {
  position: absolute;
  top: 50%;
  left: var(--inputHPadding);
  transform: translateY(-50%);
  margin-right: 0;
}
.CustomPhoneInputInput {
  width: 100%;
  height: var(--inputHeight);
  display: flex;
  align-items: center;
  font-family: var(--fontFamily);
  font-size: var(--inputFontSize);
  font-weight: 400;
  color: var(--foreground-color);
  background: var(--background-color);
  padding-right: var(--inputHPadding);
  padding-left: 48px;
  border: var(--inputBorder);
  border-radius: var(--inputRadius);
  outline: 0;
  box-shadow: none;
}
/* /react-phone-number-input/style.css */

@media (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
  h6,
  .h6 {
    font-size: 14px;
  }
  .alice-carousel__wrapper {
    margin-right: -8px !important;
    margin-left: -8px !important;
    width: calc(100% + 16px) !important;
  }
  .alice-carousel__stage-item {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media (min-width: 992px) {
  .alice-carousel__wrapper {
    margin-right: -15px !important;
    margin-left: -15px !important;
    width: calc(100% + 30px) !important;
  }
  .alice-carousel__stage-item {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
