.tabsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs {
  display: flex;
  align-items: center;
  gap: 40px;
}
.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-family: var(--fontPoppins);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  background: transparent;
  color: var(--gray);
  border-bottom: 3px solid transparent;
  border-radius: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  box-shadow: none;
}
.tab.active {
  background: transparent;
  border-bottom: 3px solid var(--primary);
  color: var(--black);
}
.panels {
  margin-top: 16px;
}
.panel {
  display: none;
  padding: 0;
}
.panel.active {
  display: block;
}
