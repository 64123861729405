.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons button {
  width: 120px;
}
.container_2 {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}
.search_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_box > div:first-child {
  width: 560px;
  position: relative;
}
.button7 {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
.b_close {
  position: absolute;
  top: 50%;
  right: 56px;
  transform: translateY(-50%);
}
.branches_list {
  padding: 50px;
  background: #d9d9d9;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.branches_list button + button {
  margin: 0;
}
.button_item {
  flex-grow: 1;
  max-width: 393px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  text-align: left;
  border-radius: 0;
  background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);
}
.b_header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}
.b_header picture {
  width: 60px;
  padding: 3px;
  border: var(--borderColor) 0.5px solid;
  box-shadow: 0px 0px 8px rgba(42, 47, 49, 0.1);
  border-radius: 4px;
}
.b_header img {
  width: 100%;
  height: 52px;
  object-fit: contain;
  border-radius: 3px;
}
.b_header > div {
  width: calc(100% - 76px);
}
.b_content {
  width: 100%;
}
.b_content h6 {
  font-size: 14px;
}
.box1 {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}
.c_h5 {
  color: #9a9c9f;
}
.c_h5_2 {
  color: var(--primary);
}
