.top_bar {
  position: sticky;
  top: 0;
  width: 100%;
  height: 48px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  z-index: calc(var(--headerIndex) + 12);
}
.top_bar button {
  color: var(--white);
  text-decoration: underline;
}
.top_bar button:hover {
  text-decoration: none;
}

.zewardBar {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
  background-color: rgba(165, 97, 216, 1);
  background-image: url(./../../assets/images/zeward_bar_bg.png);
  background-repeat: no-repeat;
  background-position: 85.5% 0;
  background-blend-mode: screen;
  padding-left: var(--contBxHPadding);
  padding-right: calc(var(--contBxHPadding) / 2);
}
.zwdContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.zwdCol {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.icon {
  line-height: 0;
}
.zwdCol span {
  font-weight: 600;
}
.zwdCol a {
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.zwdCol a:hover {
  text-decoration: none;
}

.header {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
}

.layout1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.leftBx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo a,
.logo picture {
  line-height: 0;
}

.layoutInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.toggleMenu {
  display: none;
}

.loggedUser {
  font-size: 10px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 50%;
  padding: 0;
}
.cart_control {
  position: relative;
  line-height: 0;
  padding-top: 2px;
  z-index: 101;
}
.button_cart {
  position: relative;
  background-color: transparent;
  padding: 0 11px 0 0;
  height: auto;
  width: auto;
  line-height: 0;
}
.button_cart_mobile svg,
.button_cart svg {
  width: 16px;
  height: auto;
}
.button_cart path {
  stroke: var(--textColor);
}
.button_cart > span {
  position: absolute;
  right: 0;
  top: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  font-size: 10px;
  line-height: normal;
}
.cart_popup {
  position: absolute;
  top: 100%;
  right: 0;
  width: 290px;
  background-color: var(--foreground-color);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  line-height: normal;
  margin-top: 12px;
  border-radius: 8px;
}
.cart_popup::before {
  content: '';
  position: absolute;
  top: -12px;
  right: 0;
  width: 24px;
  height: 13px;
  background: transparent;
}
.cart_popup::after {
  content: '';
  position: absolute;
  top: -12px;
  right: 18px;
  width: 0;
  height: 0;
  border-bottom: var(--gray) 13px solid;
  border-left: transparent 8px solid;
  border-right: transparent 8px solid;
}
.profile_pop {
  position: relative;
  z-index: var(--headerIndex);
}
.pop_1 {
  position: absolute;
  top: 100%;
  right: -10px;
  margin-top: 12px;
  border-radius: 6px;
}
.pop_1_before {
  content: '';
  position: absolute;
  right: 16px;
  top: -7px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border-left-width: 1px;
  border-top-width: 1px;
  border-left-style: solid;
  border-top-style: solid;
}
.profile_nav {
  min-width: 200px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}
.profile_nav li {
  position: relative;
  display: flex;
  padding: 4px 0;
  transition: all 0.2s;
  white-space: nowrap;
}
.profile_nav button {
  text-align: left;
  justify-content: flex-start;
  text-transform: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: calc(var(--headerIndex) + 99);
  transform: translateX(100%);
  transition: transform 0.25s ease;
}
.overlay.active {
  transform: translateX(0);
}

@media (max-width: 991px) {
  .toggleMenu {
    display: flex;
  }
  .toggleMenu > button {
    display: flex;
    padding: 10px;
    line-height: 0;
    border-radius: 5px;
    background: var(--primary);
    color: transparent;
    border: 0;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
  }
  .layoutInner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 200px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    transform: translateX(200px);
    transition: all 0.35s ease;
    z-index: calc(var(--headerIndex) + 99 + 11);
  }
  .menuActive {
    transform: translateX(0);
  }

  .profile_pop {
    display: none;
  }
  .user_detail_mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }
  .user_detail_mobile_hr {
    width: calc(100% + 32px);
    display: flex;
    margin-right: -16px;
    margin-left: -16px;
  }
  .profile_nav_mobile {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  .zwdContent {
    flex-direction: column-reverse;
  }
  .zwdCol {
    flex-wrap: wrap;
    width: 100%;
  }
  .zwdContent .zwdCol:nth-of-type(2) {
    justify-content: flex-end;
  }
}
